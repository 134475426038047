/******************************************/
/* Base
/******************************************/

$base-background-color: #f2f2f2;
$main-font: Arial,Helvetica,Verdana,Tahoma,sans-serif;
$main-color: #2b2b2b;
$accent-font: "Mission Gothic";

/******************************************/
/* Form
/******************************************/
$form-color: #666666;
$form-label-clr: #161616;
$form-label-weight: 600;
$form-element-color: #8b8b8b;

$dark-primary-text: #666666;
$light-primary-text: #FFFFFF;
/******************************************/
/* Header
/******************************************/

$header-bg: #FFFFFF;
$header-clr: #161616;

/******************************************/
/* Button
/******************************************/

$main-btn-bg: #072a79;
$main-btn-color: #FFF;
$main-btn-radius: 4px;
$main-btn-weight: bold;
$main-btn-txt-transform: none;

/******************************************/
/* Slider
/******************************************/

$slider-main: #dd2121;
$slider-accent: #072a79;

/******************************************/
/* FAQ
/******************************************/

$questions-arrow: #dd2121;

/******************************************/
/* Footer
/******************************************/

$footer-bg: #fff;
$footer-color: #072a79;
$footer-questions-color: #072a79;
$footer-shadow: 0 -.063rem .125rem rgba(0,0,0,.04),0 .063rem .125rem rgba(0,0,0,.04),0 .188rem .25rem rgba(0,0,0,.04);

/******************************************/
/* Steps
/******************************************/

$steps-bg: #072a79;
$steps-main-color: #072a79;
$steps-active-color: #FFFFFF;

/******************************************/
/* Secure
/******************************************/

$secure-border-bgd: #dd2121;
$secure-rds: 9px;
$secure-clr: #2b2b2b;
$secure-font: "Mission Gothic";
$secure-weight: normal;

$secure-subtitle-font: "Mission Gothic";
$secure-subtitle-weight: 300;

/******************************************/
/* Support
/******************************************/

$support-icons-color: #072a79;

/******************************************/
/* Traveler
/******************************************/

$traveler-bg: #f2f2f2;
$traveler-clr: #161616;

/******************************************/
/* Product
/******************************************/

$product-bg: #072a79;
$product-clr: #FFFFFF;
$product-weight: lighter;

/******************************************/
/* Agreement
/******************************************/
$agreement-clr: #666666;
$agreement-bg: #f2f2f2;

/******************************************/
/* Summary
/******************************************/

$summary-border-radius: 4px;
$summary-bg: #FFFFFF;
$summary-clr: #2b2b2b;
$summary-separator-width: 1px;
$summary-separator-color: #D8D8D8;
$summary-pill-bgd: #dd2121;
$summary-box-shadow:  0 2px 50px rgba(#000000, .15);
$summary-title-color: #000000;
